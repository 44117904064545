
import { Component, Prop, Vue } from 'vue-property-decorator';
import SkillListItem, { SkillItem } from './SkillListItem.vue';

@Component({
    components: {
        SkillListItem
    }
})
export default class SkillList extends Vue {
    @Prop()
    private name!: string

    @Prop()
    private skillItems!: Array<SkillItem>
}
